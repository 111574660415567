import { information } from "../const/info.const";
import { encrypt } from "../controllers/crypto.controller";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { isMobile, isTablet } from "react-device-detect";

// export const getCodeClient = async (promo_code) => {
//   const { endpoint, site } = information;
//   let myHeaders = new Headers();
//   myHeaders.append("Content-Type", "application/json");

//   let objParam = {
//     promo_code: promo_code,
//   };
//   let encryptHandle = encrypt(JSON.stringify(objParam));
//   let raw = JSON.stringify({
//     key: encryptHandle,
//   });
//   let requestOptions = {
//     method: "POST",
//     redirect: "follow",
//     headers: myHeaders,
//     body: raw,
//   };
//   return await fetch(endpoint + "/client/get-code?promo_code=" + promo_code + "&site=" + site, requestOptions)
//     .then((response) => response.json())
//     .then((result) => {
//       return result;
//     })
//     .catch((error) => console.log("error", error));
// };

export const getCodeClient = async (promo_code, captchaInput, clientToken) => {
  const { endpoint, site } = information;
  let myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", clientToken);

  let isDesktop = false;
  isDesktop = await getDeviceType();

  let objParam = {
    promo_code: promo_code,
  };
  let encryptHandle = encrypt(JSON.stringify(objParam));
  let raw = JSON.stringify({
    key: encryptHandle,
    captchaCode: captchaInput,
    token: clientToken,
    deviceType:
      isMobile || isTablet ? (isDesktop ? "desktop" : "mobile") : "desktop",
  });
  let requestOptions = {
    method: "POST",
    redirect: "follow",
    headers: myHeaders,
    body: raw,
  };
  return await fetch(
    endpoint + "/client/get-code?promo_code=" + promo_code + "&site=" + site,
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => {
      return result;
    })
    .catch((error) => console.log("error", error));
};

export const addPointClient = async (player_id, promo_code) => {
  const { endpoint, site } = information;
  let myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  let objParam = {
    promo_code: promo_code,
  };

  let encryptHandle = encrypt(JSON.stringify(objParam));
  let raw = JSON.stringify({
    key: encryptHandle,
  });

  let requestOptions = {
    method: "POST",
    redirect: "follow",
    headers: myHeaders,
    body: raw,
  };

  return await fetch(
    endpoint +
      "/client?player_id=" +
      player_id +
      "&promo_code=" +
      promo_code +
      "&site=" +
      site,
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => {
      return result;
    })
    .catch((error) => console.log("error", error));
};

export const getCaptchaTokenClient = async () => {
  const { endpoint, site } = information;
  let myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  let requestOptions = {
    method: "get",
    redirect: "follow",
    headers: myHeaders,
  };
  return await fetch(
    endpoint + "/api/get-verification-code?site=" + site,
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => {
      return result;
    })
    .catch((error) => console.log("error", error));
};

export const verifyCaptchaTokenClient = async (captchaInput, clientToken) => {
  const { endpoint, site } = information;
  let myHeaders = new Headers();
  myHeaders.append("Authorization", clientToken);
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    captchaCode: captchaInput,
    token: clientToken,
  });

  let requestOptions = {
    method: "POST",
    redirect: "follow",
    headers: myHeaders,
    body: raw,
  };
  return await fetch(
    endpoint + "/api/verify-captcha?site=" + site,
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => {
      return result;
    })
    .catch((error) => console.log("error", error));
};

const getDeviceType = async () => {
  try {
    const fp = await FingerprintJS.load();
    const result = await fp.get();
    const platform = result.components.platform?.value;
    const vendor = result.components.vendor?.value;
    const desktopKeywords = [
      "Win32",
      "Win64",
      "Windows NT",
      "MacIntel",
      "Macintosh",
      "Linux x86_64",
      "Linux i686",
      "X11",
      "FreeBSD",
      "OpenBSD",
      "NetBSD",
      "CrOS",
      "SunOS",
      "Solaris",
      "Haiku",
      "AIX",
      "HP-UX",
    ];
    const mobileKeywords = [
      "Android",
      "iPhone",
      "iPad",
      "iPod",
      "Mobile",
      "Tablet",
      "webOS",
      "BlackBerry",
      "Opera Mini",
      "IEMobile",
      "Silk",
      "armv7",
      "armv8",
      "Linux armv81",
      "Linux aarch64",
      "MeeGo",
      "Maemo",
      "Tizen",
      "KaiOS",
      "Bada",
      "Symbian",
    ];
    // alert(vendor);
    // alert(platform);
    const isDesktop =
      desktopKeywords.some((keyword) => platform.includes(keyword)) &&
      !mobileKeywords.some((keyword) => vendor.includes(keyword));
    // alert(isDesktop);
    return isDesktop;
  } catch (error) {
    console.error("Error:", error);
    return false;
  }
};
